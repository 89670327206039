<!-- 二级路由 -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
    <GgPage pageType="2" title="Classes"
	:topType="datePicker"
	@onDateClosed="onDateClosed"
	>
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <router-view @changeDate='changeDate' ref="content"></router-view>
    </GgPage>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Ajax, Utils } from "@/common";
export default {
  components: {},
  data() {
    return {
		datePicker:"",
      menuDatas: [
        {
          name: "",
          key: "classDetailInfo",
          active: true,
          children: [
            { name: "Teachers", key: "classTeachers" },
            { name: "Students", key: "classStudents" },
			{ name: "Attendance", key: "classAttendance"}
          ],
        },
      ],
      breadcrumbData: [
        {
          routeName: "locations",
          name: "",
        },
        {
          routeName: "classes",
          name: "classes",
        },
        {
          name: "",
        },
      ],
      activeIndex: "",
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
		console.log(vm)
      let className = vm.user.className;
      let schoolName = vm.user.schoolName;
      vm.menuDatas[0].name = className;
      vm.activeIndex = to.name;
      vm.breadcrumbData = [
        {
          routeName: "locations",
          name: schoolName,
        },
        {
          routeName: "classes",
          name: "Classes",
        },
        {
          name: className,
        },
      ];
    });
  },
  methods: {
	  onDateClosed(val){
		if(this.$material.locale.dateFormat=='dd/MM/yyyy'){
				this.$refs.content.query.date = moment(val,['DD/MM/YYYY']).format('YYYY-MM-DD')
			sessionStorage.setItem('date', moment(val,['DD/MM/YYYY']).format('YYYY-MM-DD'))
			
		}else{
				this.$refs.content.query.date = Utils.formatTime(val, '{y}-{m}-{d}')
			sessionStorage.setItem('date', Utils.formatTime(val, '{y}-{m}-{d}'))
		}
		console.log(this.$refs.content)
		this.$refs.content.getData();  
	  },
	  changeDate(val){
		  this.datePicker = val
	  },
    menuSelect(key) {
      this.activeIndex = key;
      // this.$router.push(`${this.$route.matched[0].path}/${key}`);
      this.$router.push({
        name: key,
        query: {
          className: this.user.className,
          classId: this.user.classId,
        },
      });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/.ggPage-main .centerPadding2{
	padding: 0px 80px 72px 32px;
}
/deep/.menu_bar {
	  margin-top: 12px;
	  width: 40px;
	  height: 40px;
	  line-height: inherit;
	  /* vertical-align: -0.65rem; */
	  .drop_down_menus {
	    padding: 0;
	  }
	  .iconmore_vert-24px{
		  font-size: 24px;
		  line-height: 40px;
		  margin-top: 0!important;
	  }
	}
</style>